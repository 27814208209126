import React from 'react'
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"

import "./Footer.scss"

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer_container'>
            <a href="https://facebook.com/dawgdotin" target="_blank" rel="noreferrer">
                            <FaFacebookSquare
                                fill="#6c6c6c"
                                className="shop_icon"
                            />
                        </a>
                <p className='footer_content'>&copy; Squeaky Compass Pvt Ltd</p>
                <a href="https://instagram.com/dawgdotin" target="_blank" rel="noreferrer">
                            <FaInstagramSquare
                                fill="#6c6c6c"
                                className="shop_icon"
                            />
                        </a>
            </div>
        </footer>
    )
}

export default Footer