/* //! eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import ImageOne from "../../images/PageOne.png";
import ImageTwo from "../../images/PageTwo.png";
import "./Brands.scss";

function Brands() {
    const [cardsWidth, setCardsWidth] = useState(window.innerWidth);
    const [blueCardActive, setBlueCardActive] = useState(true);

    useEffect(() => {
        function handleResize() {
            setCardsWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
    });

    const navigate = useNavigate();

    function handleCardClickLeft() {
        navigate("/home");
    }

    return (
        <>
            {cardsWidth > 812 && (
                <div className="our-brands">
                    <div className="brands-header">
                        <p className="brands-title-first">
                            Specialist Merchandise for Specialist Professionals
                        </p>
                        <p className="brands-title-second">
                            Select from our two brands below
                        </p>
                    </div>
                    <div className="brands-main">
                        <div
                            className={`brands-left ${
                                !blueCardActive && "brands-left-inactive"
                            }`}
                        >
                            <div
                                className="brand-card-left"
                                onClick={handleCardClickLeft}
                                onMouseEnter={() => setBlueCardActive(true)}
                            >
                                <img
                                    src={ImageOne}
                                    alt=""
                                    className="brand-card-image"
                                />
                            </div>
                            <Link className="brands-left-link" to="/home">
                                mariners
                            </Link>
                        </div>

                        <a
                            href="https://corporate.dawg.in"
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                className={`brands-right ${
                                    blueCardActive && "brands-right-inactive"
                                }`}
                                onMouseEnter={() => setBlueCardActive(false)}
                            >
                                <div className="brand-card-right">
                                    <img
                                        src={ImageTwo}
                                        alt=""
                                        className="brand-card-image"
                                    />
                                </div>
                                <a
                                    className="brands-right-link"
                                    href="https://corporate.dawg.in"
                                >
                                    corporates
                                </a>
                            </div>
                        </a>
                    </div>
                </div>
            )}
            {cardsWidth < 812 && (
                <div className="our-brands">
                    <div className="brands-header">
                        <p className="brands-title-first">
                            Specialist Merchandise for Specialist Professionals
                        </p>
                        <p className="brands-title-second">
                            Select from our two brands below
                        </p>
                    </div>
                    <div className="brands-main">
                        <div
                            className={`brands-left ${
                                !blueCardActive && "brands-left-inactive"
                            }`}
                        >
                            <div
                                className="brand-card-left"
                                onClick={blueCardActive && handleCardClickLeft}
                            >
                                <img
                                    src={ImageOne}
                                    alt=""
                                    className="brand-card-image"
                                />
                                {!blueCardActive && (
                                    <button
                                        className="button_left"
                                        onClick={() => setBlueCardActive(true)}
                                    ></button>
                                )}
                            </div>
                            <Link className="brands-left-link" to="/home">
                                mariners
                            </Link>
                        </div>
                        <a
                            href={
                                blueCardActive === true
                                    ? "#"
                                    : "https://corporate.dawg.in"
                            }
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                className={`brands-right ${
                                    blueCardActive && "brands-right-inactive"
                                }`}
                            >
                                <div className="brand-card-right">
                                    <img
                                        src={ImageTwo}
                                        alt=""
                                        className="brand-card-image"
                                    />
                                    {blueCardActive && (
                                        <button
                                            className="button_right"
                                            onClick={() =>
                                                setTimeout(() => {
                                                    setBlueCardActive(false);
                                                }, 1)
                                            }
                                        ></button>
                                    )}
                                </div>
                                <a
                                    className="brands-right-link"
                                    href="https://corporate.dawg.in"
                                >
                                    corporates
                                </a>
                            </div>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

export default Brands;
