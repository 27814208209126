import React, { useEffect } from "react";

import "./Shop.scss";
import HomeButton from "../../components/HomeButton/HomeButton";
import SeaDawgMark from "../../components/SeaDawgMark/SeaDawgMark";
import CircularHeader from "../../components/CircularHeader/CircularHeader";
import flagImage from "./flag.png"



function Shop() {
    useEffect(() => {
        document.title = "dawg.in | Shop";
    })
    return (
        <div className="shop">
            <HomeButton />
            <SeaDawgMark />
            <CircularHeader color="#fbcc00" heading="STORE LAUNCHING AUGUST 15, 2022!" marginTop="130px" fontS="34px" backPic={flagImage}>
                <div className="content_first">

            {/*store Launching
august 15, 2022!

HEAVE TIGHT, AND MAKE FAST!

We are Launching our store and commencing sales on Independence Day - August 15, 2022

What better day to start at the PLATINUM JUBILEE of our country's Independence?

There's lots to do on our website until then. Submit a story or an article to be published on our blog, or enjoy our very own social platform for seafarers, for instance.
 

In the meantime, do not miss our teasers and reveals on social media. Remember, we're not a shop, but a community. Do write back to us and let's get the conversations going!*/}
                    <h3>HEAVE TIGHT, AND MAKE FAST!</h3>
                    <br />
                    <br />
                    We are Launching our store and commencing sales on Independence Day - August 15, 2022
                    <br />
                    <br />
                    What better day to start at the PLATINUM JUBILEE of our country's Independence?
                    <br />
                    <br />
                    There's lots to do on our website until then. Submit a story or an article to be published on our blog, or enjoy our very own social platform for seafarers, for instance.
                    <br />
                    <br />
                    In the meantime, do not miss our teasers and reveals on social media. Remember, we're not a shop, but a community. Do write back to us and let's get the conversations going!
                    {/*<div className="shop_icon_container">
                        <a href="https://facebook.com/dawgdotin" target="_blank" rel="noreferrer">
                            <FaFacebookSquare
                                fill="#fbcc00"
                                className="shop_icon"
                            />
                        </a>
                        <a href="https://instagram.com/dawgdotin" target="_blank" rel="noreferrer">
                            <FaInstagramSquare
                                fill="#fbcc00"
                                className="shop_icon"
                            />
                        </a>
                    </div>*/}
                </div>
            </CircularHeader>
        </div>
    );
}

export default Shop;
